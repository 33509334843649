@import url('https://fonts.googleapis.com/css?family=IBM+Plex+Sans:200,200i,300,300i,500,500i,600,600i&display=swap');

/*
    COLOR VARIABLES (from zepplin)
    Reference: https://app.zeplin.io/project/5d6646dc07b3b69b66d736b6/screen/5d9337b5ee805f15b1a489a7

    Naming Convention:
    Reference: https://material.io/design/color/#color-usage-palettes

    We have in total 16 unique colors variants across the light/dark mode and 6 base colors in total.
    Hence the naming that made most sense was the way Google does it, ie naming the color and using multiple of 50 to highlight the lightness of it.
    i.e - $color-900 [darkest]
        - $color-50 [lightest]

    When adding new colors, the first one to be added should by default be a 900 color as by itself its still the darkest on.
    When a new variant of the color is added again, the number should be relatively logical in terms of brightness.
    Ideally having a difference of 300 to start with is a good pattern. 300,600,900. and then dividing it by 2 if variants get too many.
*/

$blue-900: #233049;
$blue-600: #50a3ec;

// White generally doesn't have any 'dark' or 'light' variation hence this is an outlaw.
// ie. we dont need to suffix it with '900' or any other number.
$white: #ffffff;

$grey-900: #656e7f;
$grey-600: #9097a3;
$grey-300: #bdc0c8;
$grey-100: #d5d7dc;

$orange-900: #5e4436;
$orange-600: #e9730c;
$orange-300: #f8d5b6;

$red-900: #502132;
$red-600: #bb0000;
$red-300: #eab2b2;

$green-900: #1d4745;
$green-800: #107e3e;
$green-300: #b7d8c5;

/* CSS variables */
// Sets up the base variables which can then be overidden based on modes.
// Main aim is to use these everywhere (or at least use variables based on these
// We can even change these in pure JS - root.style.setProperty('--primary', colorHex)
:root {
    --primary: #{$blue-900};
    --secondary: #{$white};

    --accent: #{$blue-600};

    --white: #{$white};

    --grey: #{$grey-900};
    --grey-secondary: #{$grey-600};

    --warning: #{$orange-900};
    --warning-secondary: #{$orange-600};

    --error: #{$red-900};
    --error-secondary: #{$red-600};

    --success: #{$green-900};
    --success-secondary: #{$green-800};

    --shadow: rgba(0, 0, 0, 0.3);
}

/*
    FONT
*/
// Base font-size to achieve 4px system for font sizes
$font-size-multiplier: 0.4rem;
// Font Source of Truth
// This map is the source of truth for the font sizes and must be used as such
$font-sizes: (
    4xl: $font-size-multiplier * 12,
    3xl: $font-size-multiplier * 10,
    2xl: $font-size-multiplier * 9,
    xl: $font-size-multiplier * 8,
    l: $font-size-multiplier * 6,
    m: $font-size-multiplier * 5,
    s: $font-size-multiplier * 4.5,
    // default
    xs: $font-size-multiplier * 3,
);

// CAUTION
// This sets the definition/value for REM in the entire system.
// its 62.5% because base browser has base font size as 16.
// 62.5% of 16 = 10, making 1rem = 10 therby making calculations easier.
$base-font-size: 62.5%;
$font-size-default: map-get($font-sizes, s);
$font-family-primary: 'IBM Plex Sans', 'Helvetica Neue', Arial, sans-serif;
$line-height-default: 1.4;

// Font weights
$font-weights: (
    extra-light: 200,
    light: 300,
    regular: 500,
    bold: 600,
) !default;

/*
    BREAKPOINTS
*/
$breakpoints: (
    xs: 375px,
    sm: 576px,
    md: 768px,
    lg: 992px,
    xl: 1200px,
);

/* CURSORS */
$cursor-disabled: not-allowed;

/* SPACE BETWEEN ELEMENTS WITHIN A COMPONENT */
$internal-space-multiplier: 0.4rem;

/* BOOTSTRAP GRID SETTINGS */
$grid-gutter-width: 32px;
